.WalkshedOriginPanel {

    
    .panel-source-selector {
        // margin: 15px 15px 15px 15px;
        padding: 10px 10px 10px 25px;
        

        .ReactForm__RadioSlider{

            .ReactForm__RadioSlider-options{

                
            .ReactForm__RadioSlider-option {

                font-size: 10px;
                padding: 5px 5px 5px 5px;
                // overflow: hidden;
    // white-space: nowrap;
                
            }
            }
        }
    }
}