.CategoriesPanel {
    width: 100%;
    ul {
        padding-left: 10px;

    }
    .ItemCategory {
        position: absolute;
        margin: 7px 0px 7px 0px;
        box-sizing: border-box;
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px 15px 0px 0px;
        overflow: visible;
        min-height: 40;
        align-content: center;
        flex-wrap: nowrap;
        gap: 15;

        transition: top .5s;

        .rectWrapper {
            display: flex;
            align-items: center;
            width: 100%;
            .Checkbox {
                flex: 0 0 1rem;
            }
            

            .checklistItem {
                border: none;

            }
            .CheckListItem.checked {
                background: none;
            }
            .rects {
                box-sizing: border-box;
                width: 15px;
                height: 35px;
                overflow: visible;
                border-radius: 5px 0px 0px 5px;
                border-color: #F2F5FA;
                border-style: solid;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-left-width: 1px;
                border-right-width: 0px;
                cursor: pointer;
                margin-left: 10px;

                flex: 0 0 15px;


            }


            .categoryLabel {
                box-sizing: border-box;
                flex: 1 1 150px;
                height: 35px;
                width: 200px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 10px 10px 10px;
                background-color: var(--token-498f5f00-091f-442f-aed7-469a644be658, #ffffff);
                overflow: visible;
                align-content: start;
                flex-wrap: nowrap;
                gap: 0;
                border-color: #F2F5FA;
                border-style: solid;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-left-width: 0px;
                border-right-width: 0px;
                flex-basis: 100px;
                text-align: left;



            }


            .countPerCats {
                align-items: flex-end;

                // flex: 0.2;
                
                width: 35px;
                height: 35px;

                box-sizing: border-box;
                background-color: #f2f5fa;
                overflow: visible;
                border-radius: 0px 5px 5px 0px;
                border-color: #F2F5FA;
                border-style: solid;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-left-width: 0px;
                border-right-width: 1px;

                display: flex;
                align-items: center;
                justify-content: space-around;


                &Off { color: gray;
                  align-items: flex-end;
                  flex: 0 0 35px;

                // flex: 0.2;
                width: 35px;
                height: 35px;

                box-sizing: border-box;
                background-color: #f2f5fa;
                overflow: visible;
                border-radius: 0px 5px 0px 5px;
                border-color: #F2F5FA;
                border-style: solid;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-left-width: 0px;
                border-right-width: 1px;

                display: flex;
                align-items: center;
                justify-content: space-around;

                }
               

            }
            .bardivs {

                &-all{

                    border-top-width: 0px;
                border-bottom-width: 0px;
                border-left-width: 0px;
                border-right-width: 0px;
                }

                box-sizing: border-box;
                // width: 15px;
                height: 35px;
                overflow: visible;
                border-radius: 0px 5px 5px 0px;
                border-color: #050038;
                opacity: 0.5;
                background-color: #F2F5FA;
                border-style: solid;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-left-width: 0px;
                border-right-width: 1px;
                margin-left: 15px;
            }

            
           
        }
    }
}